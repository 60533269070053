div.slide {
  /* background-attachment: fixd; */
  background-image: url("../assets/images/sewing-machine-8106959_1920.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
div.slide .slide_overlay {
  background-color: #000000a1;
}

div.slide2 {
  background-attachment: fixed;
  background-image: url("https://i.pinimg.com/originals/bb/0b/7e/bb0b7e1aa708044c59cf01f2c177b639.jpg");
  background-image: url("../assets/images/fash1.png");
  /* background-image: url("https://i.pinimg.com/originals/bb/0b/7e/bb0b7e1aa708044c59cf01f2c177b639.jpg"); */
  background-size: 400px;
  background-repeat: no-repeat;

  background-position: top left;
}

div.slide3 {
  background-attachment: ;
  background-image: url("../assets//images/scissors-1008907_1280.jpg");
  /* background-image: url("https://i.pinimg.com/originals/bb/0b/7e/bb0b7e1aa708044c59cf01f2c177b639.jpg"); */
  background-size: cover;
  background-repeat: no-repeat;

  background-position: bottom right;
}

.skew-c {
  width: 100%;
  height: 100px;
  /* position:absolute; */
  left: 0px;
  /* background: linear-gradient(to left bottom, #ffffff 49%, #068D62 50%); */
}

.colour-block {
  /* background: linear-gradient(180deg,  #068D62 , #0f5b46 80%); */
  /* background:#823A9C; */
  /* background: #0f5b46; */
  width: 100%;
  /* padding: 10% 5%; */
  color: #fff;
}
.skew-cc {
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0px;
  background: linear-gradient(to right bottom, #823a9c 49%, #fff 50%),
    linear-gradient(-50deg, #ffffff 16px, #000 0);
}
.ceo_bg{
  background-image: url();
  /* background: #000; */
}